html {
    width: 100%;
    height: 100%;
    background-color: #E7EBF0;
}

body {
	font-family: 'Salesforce Sans', Arial, sans-serif;
}

h3, label {
	color: #16325c;
}

label {
	font-size: 1rem;
	line-height: 1.25;
}

.form-group.required .control-label:before {
	content:"* ";
	color:red;
}

.error-msg {
	color: red;
	margin-bottom: 10px;
}

.content-header {
  position: relative;
}
